import React from "react"
import PropTypes from "prop-types"
import {Helmet} from 'react-helmet'
import Header from "./header"

const Layout = (props) => {
  var title = "Knockout Photo | Real Estate Photography | Orange County";
  if (props.siteTitle){
    title = "Knockout Photo - " + props.siteTitle;
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta charSet="utf-8" />
        <meta property="og:title" content="Knockout Photo" />
        <meta property="og:description" content="Taking top-quality photos to help you sell your home." />
        <meta property="og:image" content="http://knockoutphoto.com" />
        <meta property="og:url" content="https://knockoutphoto.com" />
        <meta property="og:site_name" content="Knockout Photo" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image:alt" content="Luxurious home" />
      </Helmet>

      <Header />
      <div>

        <main className="flex-1 overflow-y-auto bg-white">{props.children}</main>

        <footer>

          <div className="bg-gray-700  py-5 px-5 flex flex-row text-white w-full">
            
            <div className="flex flex-col text-xs sm:text-xl self-center">
            © {new Date().getFullYear()} Knockout Photo
            </div>
          
            <div className="flex flex-col flex-1 text-xs sm:text-xl space-y-3 text-right justify-end">
              <div>(714) 872-9535</div>
              <div>info@knockoutphoto.com</div>
            </div>

          </div>
          
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
