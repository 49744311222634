import React from 'react'
import {Link} from "gatsby"
import Layout from '../components/layout'
import icon from "../images/360logo.webp"

const TourPage = () => (
    <Layout siteTitle="360 Tour">
        <div className="bg-gray-200">
            <div className="flex justify-center">
                <img src={icon} alt="360 tour" className="flex py-14" />
            </div>

            <div className="bg-white grid gap-16 lg:gap-0 grid-flow-row lg:grid-cols-1 lg:divide-x-2 lg:divide-gray-400 lg:divide-solid p-10 mb-10">

                <div className="justify-self-center">
                    <ul className="text-3xl space-y-10">
                        <li className="font-bold text-5xl">AVAILABLE TIME SLOTS</li>
                        <li>10:00 am.....................Monday through Sunday</li>
                        <li>12:00 Noon.................Monday through Sunday</li>
                        <li>2:00 pm.......................Monday through Sunday</li>
                        <li>4:30 pm*........................Monday through Sunday</li>
                        <li>*special discounted price only for this time slot</li>
                    </ul>
                </div>   
            </div>

            <div className="bg-gray-800 text-white font-bold flex flex-col lg:flex-row lg:h-48 px-10 py-5">
                <div className="flex-col self-center space-y-10">
                    <h2 className="text-5xl text-center lg:text-justify mb-5 lg:mb-0">Book our 4:30 PM time slot and get special pricing here</h2>
                </div>

                <div className="self-center bg-blue-400 text-center p-10 mx-16 hover:bg-white hover:text-black text-2xl lg:text-4xl">
                    <Link to="https://knockoutphoto.hd.pics/view/default.asp?s=365600">View Price</Link>
                </div>
            </div>

            <div className="px-10 py-12">
                <h2 className="text-5xl font-bold text-center mb-24">360° Virtual Tour Samples</h2>
                <div className="grid grid-flow-row lg:grid-cols-2 grid-cols-1 gap-9">
                    <iframe style={{"width": "100%", "height": "640px", "border": "none", "max-width": "100%"}} frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7PnX7?fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1&info=0&logo=-1"></iframe>
                    <iframe style={{"width": "100%","height": "640px", "border":"none","max-width": "100%"}} frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7PFt5?fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1&info=0&logo=-1"></iframe>
                    <iframe style={{"width": "100%", "height": "640px", "border": "none", "max-width": "100%"}} frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7P8tP?fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1&info=0&logo=-1"></iframe>
                    <iframe style={{"width": "100%", "height": "640px", "border": "none", "max-width": "100%"}} frameborder="0" allowfullscreen allow="xr-spatial-tracking; gyroscope; accelerometer" scrolling="no" src="https://kuula.co/share/collection/7PkMB?fs=1&vr=1&zoom=1&sd=1&initload=0&thumbs=1&info=0&logo=-1"></iframe>
                </div>
            </div>

            <div className="px-16 py-10 grid space-y-5 bg-white">
                <h3 className="font-bold text-3xl justify-self-center">Cancellation or Rescheduling</h3>
                <p className="text-2xl justify-self-center">
                    Given at least 1 day's notice, there is no charge for either cancellation or rescheduling.<br/>
                    For a same-day cancellation or rescheduling, there is a cancellation fee of $50 for daylight shoots and $100 for twilight shoots.
                </p>
            </div>

        </div>
        
    </Layout>
)

export default TourPage