import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import KnockoutPhoto from '../images/KnockoutPhoto.png'
import "./header.css"

const Header = () => {
  const [open, setOpen] = useState(false);
  function handleClick(e){
    if(open){
      e.target.classList.remove("hidden");
      setOpen(!open)
    }
      
    else
      e.target.classList.add("hidden")
  }
  
  useEffect(() => {
    function handleOpenStatus(open){
      if(open){
        document.getElementById("hamburgercontent").classList.replace("hidden", "flex");
      } else {
        document.getElementById("hamburgercontent").classList.replace("flex", "hidden");
      }
    }

    handleOpenStatus(open)
  }, [open])

  return (
    <header className="sticky z-50 top-0 bg-white shadow w-full">
      <div className="py-3 px-5 flex flex-row items-center justify-between">
        
        <div className="flex-col w-2/12 md:mr-14 mr-5">
          <Link to="/" className="w-full">
            <img src={KnockoutPhoto} alt="Knockout Logo" className="w-full" style={{"minWidth" : "90px", "minHeight" : "40px"}} />
          </Link>
        </div>

        <div className="sm:flex flex-row flex-1 space-x-5 text-2xl md:text-3xl hidden">
          
          <div className="relative" id="dropdown">
            <button className="cursor-pointer hover:text-blue-300">Services</button> 
            <div id="dropcontent" className="hidden absolute py-11">
              <div className="flex flex-col text-center bg-white w-max space-y-3 shadow-xl p-3 rounded-md">
                <Link to="/photos" className="cursor-pointer hover:text-blue-300">Photos</Link>
                <Link to="/360tour" className="cursor-pointer hover:text-blue-300">3D Virtual Tour</Link>
                <Link to="/video" className="cursor-pointer hover:text-blue-300">Video</Link>
              </div>
            </div>
          </div>
          
          <Link to="/gallery" className="cursor-pointer hover:text-blue-300">Gallery</Link>
          <Link to="/about" className="cursor-pointer hover:text-blue-300">About</Link>
          <Link to="/contact" className="cursor-pointer hover:text-blue-300">Contact</Link>
        </div>

        <div className="flex-col right-2">
          <Link external to="https://knockoutphoto.hd.pics/view/default.asp?s=365600" className="p-3 justify-self-end bg-black text-white hover:bg-white hover:text-black border-black border border-solid mr-2 sm:block hidden">
            Book Now
          </Link>
        </div>

        <div className="sm:hidden block">
          <button className="hover:bg-black hover:text-white border-solid border-black border-2 p-2 rounded-sm" onClick={() => setOpen(!open)}>
            MENU
          </button>
          <div id="hamburgercontent" className="hidden absolute bg-white flex-col rounded-md p-5 text-2xl space-y-3 text-right right-1">
            <Link to="/" className="cursor-pointer hover:text-blue-300">Home</Link>
            <Link to="/photos" className="cursor-pointer hover:text-blue-300">Photos</Link>
            <Link to="/360tour" className="cursor-pointer hover:text-blue-300">3D Virtual Tour</Link>
            <Link to="/video" className="cursor-pointer hover:text-blue-300">Video</Link>
            <Link to="/gallery" className="cursor-pointer hover:text-blue-300">Gallery</Link>
            <Link to="/about" className="cursor-pointer hover:text-blue-300">About</Link>
            <Link to="/contact" className="cursor-pointer hover:text-blue-300">Contact</Link>
            <Link to='/book' className="cursor-pointer bg-black text-white p-1 text-center hover:text-blue-300">BOOK NOW</Link>
          </div>
        </div>
        
      </div>
    </header>
  )
}

export default Header
